import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Space,
  Tooltip,
  Badge,
  Popover,
  Modal,
  Empty,
  message,
  Divider,
  Flex,
  Tabs,
} from "antd";
import {
  EditOutlined,
  DragOutlined,
  DeleteOutlined,
  CopyOutlined,
  MoreOutlined,
  CheckOutlined,
  PlusOutlined,
  FileAddOutlined,
  VerticalAlignMiddleOutlined,
  ExclamationCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ColumnHeightOutlined,
  BranchesOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { BsBan, BsAsterisk } from "react-icons/bs";

//components
import HorizontalMenu from "../../../components/app/builder/HorizontalMenu";
import AbaQuestion from "./AbaQuestion";
import AbaLogics from "./AbaLogics";
import AbaLayout from "./AbaLayout";
import AbaTranslate from "./AbaTranslate";
import AbaPage from "./AbaPage";
import AbaLogicPage from "./AbaLogicPage";
import EmptyBox from "../../../components/ui/EmptyBox";
import Loading from "../../../components/ui/Loading";

//utils
import { getStorage, setStorage, deleteStorage } from "../../../utils/Storage";
import { setSession, deleteSession, getSession } from "../../../utils/Session";
import { jsonReader, arrayReader } from "../../../utils/Arrays";
import i18n from "../../../utils/i18n";
import { readLogics } from "../../../utils/Objects";

//component questions
import NPSComponent from "./components/NPS";
import PlainTextComponent from "./components/PlainText";
import DropdownLanguageComponent from "./components/DropdownLanguage";
import RadioboxComponent from "./components/Radiobox";
import CSATComponent from "./components/CSAT";
import CESComponent from "./components/CES";
import CheckboxComponent from "./components/Checkbox";
import OptinComponent from "./components/Optin";
import DropdownComponent from "./components/Dropdown";
import InputComponent from "./components/Input";
import TextareaComponent from "./components/Textarea";
import AutoCompleteComponent from "./components/AutoComplete";
import ImageUploadComponent from "./components/ImageUpload";
import ResetButtonComponent from "./components/ResetButton";
import MosaicComponent from "./components/Mosaic";

//redux
import {
  getSurveyById,
  updateSurveyById,
} from "../../../store/builder/builder.action";
import {
  loadingRedux,
  windowWidthRedux,
} from "../../../store/application/app.slice";
import {
  surveyData,
  progressLoading,
  setProgressLoading,
  openEditorX,
  openEditorPageX,
  setOpenEditor,
  setOpenEditorPage,
} from "../../../store/builder/builder.slice";

//styles
import "../../../assets/styles/builder/build.css";

const BuilderHomePage = () => {
  const { surveyid } = useParams();
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();
  const [modal, contextHolderModal] = Modal.useModal();

  //redux
  const loading = useSelector(loadingRedux);
  const surveyDataRedux = useSelector(surveyData);
  const progressLoadingX = useSelector(progressLoading);
  const openEditor = useSelector(openEditorX);
  const openEditorPage = useSelector(openEditorPageX);
  const ww = useSelector(windowWidthRedux);

  const [showModalDeletePage, setShowModalDeletePage] = useState(false);
  const [showModalDeleteQuestion, setShowModalDeleteQuestion] = useState(false);
  const [showModalMovePage, setShowModalMovePage] = useState(false);
  const [showModalMoveQuestion, setShowModalMoveQuestion] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [showModalEditor, setShowModalEditor] = useState(false);
  const [showModalEditorPage, setShowModalEditorPage] = useState(false);
  const [showModalAction, setShowModalAction] = useState(false);
  const [showModalLogic, setShowModalLogic] = useState(false);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [maxQuestionNumber, setMaxQuestionNumber] = useState(1);
  const [jsonData, setJsonData] = useState(null);
  const [logicText, setLogicText] = useState("");

  const addScrollReader = () =>
    window.addEventListener("scroll", () => {
      setSession("SCROLL_" + surveyid, window.scrollY);
    });

  useEffect(() => {
    addScrollReader();
    return () => addScrollReader();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    deleteStorage("EDITION_" + surveyid);
    deleteStorage("LOGIC_" + surveyid);
    deleteSession("ACTIVE_TAB_" + surveyid);
    deleteStorage("SURVEY_" + surveyid);
    dispatch(getSurveyById(surveyid));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let surveyLocal = getStorage("SURVEY_" + surveyid, true);
    if (surveyLocal !== "" && Object.keys(surveyLocal).length > 0) {
      setJsonData(surveyLocal);
      extractMaxPageAndQuestion(surveyLocal);
    }
    // eslint-disable-next-line
  }, [surveyDataRedux]);

  const deepReverse = (arr) => {
    if (!Array.isArray(arr)) {
      return "Input is not an array";
    }

    return arr
      .map((element) => {
        if (Array.isArray(element)) {
          return deepReverse(element);
        } else {
          return element; // Otherwise, return the element as it is
        }
      })
      .reverse(); // Reverse the array after mapping
  };

  const extractMaxPageAndQuestion = (survey) => {
    let maxPageNum = 1;
    let maxQuestionNum = 1;

    survey.pages.forEach((p) => {
      if (p.id > maxPageNum) {
        maxPageNum = p.id;
      }
      p.questions.forEach((q) => {
        if (q.id > maxQuestionNum) {
          maxQuestionNum = q.id;
        }
      });
    });

    setMaxPageNumber(maxPageNum);
    setMaxQuestionNumber(maxQuestionNum);
  };

  const globalSurveySave = (props = {}) => {
    let { redirect, message, openEditor } = props;

    redirect = redirect || false;
    message = message || "";
    openEditor = openEditor || false;

    let surveyLocal = getStorage("SURVEY_" + surveyid, true);
    let logicLocal = getStorage("LOGIC_" + surveyid, true);
    let questionLocal = getStorage("EDITION_" + surveyid, true);
    let pageLocal = getStorage("EDITION_PAGE_" + surveyid, true);
    let logicLocalPage = getStorage("LOGIC_PAGE_" + surveyid, true);

    /**** QUESTION ****/
    if (logicLocal !== "" && logicLocal !== undefined) {
      surveyLocal.logics.questions[currentQuestion.questionid] = arrayReader(
        deepReverse(logicLocal)
      );
    } else {
      //deleta quando for uma edição de questão
      if (currentQuestion !== null && currentQuestion?.questionid) {
        if (surveyLocal.logics.questions?.[currentQuestion.questionid]) {
          delete surveyLocal.logics.questions[currentQuestion.questionid];
        }
      }
    }

    if (questionLocal !== "" && currentQuestion !== null) {
      surveyLocal.pages[currentQuestion.pagePosition].questions[
        currentQuestion.position
      ] = questionLocal;
    }

    /**** PAGE ****/
    if (logicLocalPage !== "" && logicLocalPage !== undefined) {
      surveyLocal.logics.pages[currentPage.pageid] = arrayReader(
        deepReverse(logicLocalPage)
      );
    } else {
      //deleta quando for uma edição de página
      if (currentPage !== null && currentPage?.pageid) {
        if (surveyLocal.logics.pages?.[currentPage.pageid]) {
          delete surveyLocal.logics.pages[currentPage.pageid];
        }
      }
    }

    if (pageLocal !== "" && currentPage !== null) {
      surveyLocal.pages[currentPage.position] = pageLocal;
    }

    dispatch(
      updateSurveyById(surveyid, surveyLocal, redirect, message, openEditor)
    );
  };

  const addNewPage = (pageIdRef) => {
    dispatch(setProgressLoading(true));
    let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);
    let newPages = [];

    jsonDataCopy.pages.forEach((page) => {
      if (pageIdRef === page.id) {
        newPages.push(page);
        newPages.push({
          id: maxPageNumber + 1,
          active: true,
          name: i18n.NEW_PAGE,
          questions: [],
        });
      } else {
        newPages.push(page);
      }
    });

    jsonDataCopy.pages = newPages;
    setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
    globalSurveySave();
  };

  const deletePage = () => {
    dispatch(setProgressLoading(true));
    const { position } = currentPage;
    let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);
    jsonDataCopy.pages.splice(position, 1);

    setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
    globalSurveySave({ message: i18n.DELETE_PAGE_SUCCESS });
    setShowModalDeletePage(false);
    setCurrentPage(null);
  };

  const addQuestion = ({ indexPage, indexQuest, isEmptyPage }) => {
    dispatch(setProgressLoading(true));
    let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);

    let nextMaxQuestionNumber = maxQuestionNumber + 1;
    let langToTitle = {};

    jsonDataCopy.languageOptions.forEach((l) => {
      langToTitle[l.value] = i18n.TEXT_EXAMPLE_NEW_QUESTION;
    });

    let questionStructure = {
      id: nextMaxQuestionNumber,
      active: true,
      options: [],
      properties: {},
      require: false,
      title: langToTitle,
      type: "text",
    };

    //Cria uma questão em uma página vazia
    if (isEmptyPage) {
      jsonDataCopy.pages[indexPage].questions.push(questionStructure);
    } else {
      //cria uma questão quando já existem outra questões
      let newQuestions = [];

      jsonDataCopy.pages[indexPage].questions.forEach(
        (question, ixquestion) => {
          if (indexQuest === ixquestion) {
            newQuestions.push(question);
            newQuestions.push(questionStructure);
          } else {
            newQuestions.push(question);
          }
        }
      );

      jsonDataCopy.pages[indexPage].questions = newQuestions;
    }

    setCurrentQuestion({
      questionid: nextMaxQuestionNumber,
      position: isEmptyPage ? 0 : indexQuest + 1,
      pagePosition: indexPage,
    });

    setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
    setStorage("EDITION_" + surveyid, questionStructure, true);
    setStorage("LOGIC_" + surveyid, "");
    globalSurveySave({ openEditor: true });
  };

  const deleteQuestion = () => {
    dispatch(setProgressLoading(true));
    setShowModalDeleteQuestion(false);

    const { questionid, pagePosition } = currentQuestion;
    let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);

    jsonDataCopy.pages[pagePosition].questions = jsonDataCopy.pages[
      pagePosition
    ].questions.filter((question) => question.id !== questionid);

    //exclui lógica se existir
    if (jsonDataCopy.logics.questions[questionid]) {
      delete jsonDataCopy.logics.questions[questionid];
    }

    setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
    globalSurveySave({ message: i18n.DELETE_QUESTION_SUCCESS });
    setTimeout(() => {
      setCurrentQuestion(null);
    }, 1000);
  };

  const duplicateQuestion = ({ questionid, position, pagePosition }) => {
    dispatch(setProgressLoading(true));
    let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);
    let localQuestions = jsonDataCopy.pages[pagePosition].questions;
    let newLocalQuestions = [];
    const newID = maxQuestionNumber + 1;

    localQuestions.forEach((question, iquestion) => {
      newLocalQuestions.push(question);

      if (question.id === questionid && iquestion === position) {
        //quando  radiobox|checkbox, altera os IDs das opções
        if (["radiobox", "checkbox"].includes(question.type)) {
          question.options.map((v, i) => {
            v.id = `${newID}-${i + 1}`;
            return v;
          });
        }

        //copiando com novo id
        newLocalQuestions.push({ ...question, id: newID });

        //copiando lógica se existir
        if (jsonDataCopy.logics.questions?.[question.id]) {
          jsonDataCopy.logics.questions[newID] =
            jsonDataCopy.logics.questions[question.id];
        }
      }
    });

    jsonDataCopy.pages[pagePosition].questions = newLocalQuestions;
    setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
    globalSurveySave({ message: i18n.DUPLICATE_QUESTION_SUCCESS });
    setCurrentQuestion(null);
  };

  const duplicatePage = (currentDataPage) => {
    dispatch(setProgressLoading(true));
    const { pageid, position } = currentDataPage;

    let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);
    let localPages = jsonDataCopy.pages;
    let newLocalPages = [];
    let maxNumQuest = maxQuestionNumber;

    localPages.forEach((page, ipage) => {
      if (page.id === pageid && ipage === position) {
        newLocalPages.push(page);

        let pageCopy = { ...page };
        let newLocalQuestions = [];
        pageCopy.questions.forEach((quest) => {
          maxNumQuest++;
          newLocalQuestions.push({ ...quest, id: maxNumQuest });
        });
        pageCopy.questions = newLocalQuestions;
        newLocalPages.push({ ...pageCopy, id: maxPageNumber + 1 });
      } else {
        newLocalPages.push(page);
      }
    });

    jsonDataCopy.pages = newLocalPages;
    setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
    globalSurveySave({ message: i18n.DUPLICATE_PAGE_SUCCESS });
  };

  const mergePages = (pageIdRef, indexPage) => {
    modal.confirm({
      title: i18n.CAUTION + "!",
      icon: <ExclamationCircleOutlined />,
      content: i18n.CAUTION_MERGE_PAGE,
      okText: "OK",
      cancelText: i18n.CANCEL,
      onOk: () => {
        dispatch(setProgressLoading(true));
        let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);
        let newPages = [];

        let thisPage = jsonDataCopy.pages[indexPage];
        let pageTarget = jsonDataCopy.pages[indexPage + 1];

        jsonDataCopy.pages.forEach((page) => {
          if (pageIdRef === page.id) {
            newPages.push({
              id: maxPageNumber + 1,
              active: true,
              name: i18n.MERGED_PAGE,
              questions: [...thisPage.questions, ...pageTarget.questions],
            });
          } else {
            if (page.id !== pageTarget.id) {
              newPages.push(page);
            }
          }
        });

        jsonDataCopy.pages = newPages;
        setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
        globalSurveySave();
      },
    });
  };

  const breakPage = (indexPage, indexQuest) => {
    modal.confirm({
      title: i18n.CAUTION + "!",
      icon: <ExclamationCircleOutlined />,
      content: i18n.CAUTION_BREAK_PAGE,
      okText: "OK",
      cancelText: i18n.CANCEL,
      onOk: () => {
        dispatch(setProgressLoading(true));
        let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);

        let thisPage = jsonDataCopy.pages[indexPage];

        let questionsPage1 = [];
        let questionsPage2 = [];

        thisPage.questions.forEach((q, i) => {
          i <= indexQuest ? questionsPage1.push(q) : questionsPage2.push(q);
        });

        jsonDataCopy.pages[indexPage].questions = questionsPage1;

        jsonDataCopy.pages.splice(indexPage + 1, 0, {
          name: "Página Quebrada",
          questions: questionsPage2,
          active: true,
          id: maxPageNumber + 1,
        });

        setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
        globalSurveySave();
      },
    });
  };

  const MovePage = () => {
    const { pageid } = currentPage;
    let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);

    //remove a thank you page
    let newPages = jsonDataCopy.pages.filter((page) => page.id !== 2);

    const [sortPage, setSortPage] = useState(newPages);

    const movePage = (direction, position) => {
      let sortPageCopy = [...sortPage];

      if (direction === "up") {
        sortPageCopy.splice(
          position - 1,
          2,
          sortPage[position],
          sortPage[position - 1]
        );
      } else {
        sortPageCopy.splice(
          position,
          2,
          sortPage[position + 1],
          sortPage[position]
        );
      }

      setSortPage(sortPageCopy);
    };

    const saveSortPage = () => {
      dispatch(setProgressLoading(true));
      jsonDataCopy.pages = [...sortPage, jsonDataCopy.pages.pop()];
      setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
      globalSurveySave({ message: i18n.MOVED_PAGE + "!" });
      setShowModalMovePage(false);
    };

    return (
      <div id="build-page-sortable-box">
        {sortPage !== null &&
          sortPage.map((sPage, spx) => {
            return (
              <div
                className={`sort-item${pageid === sPage.id ? " selected" : ""}`}
                key={`sortable-page-item-${spx}`}
              >
                <div className="btn-box">
                  <Button
                    icon={<ArrowDownOutlined />}
                    onClick={() => movePage("down", spx)}
                    disabled={sortPage.length === spx + 1}
                  />
                  <Button
                    icon={<ArrowUpOutlined />}
                    onClick={() => movePage("up", spx)}
                    disabled={spx === 0}
                  />
                </div>
                <div className="desc-box">
                  {pageid === sPage.id ? (
                    <strong>
                      #{sPage.id} {sPage.name}
                    </strong>
                  ) : (
                    <span>
                      #{sPage.id} {sPage.name}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        <Flex
          gap="small"
          wrap="wrap"
          justify="right"
          style={{ marginTop: "10px" }}
        >
          <Button onClick={() => setShowModalMovePage(false)}>
            {i18n.CANCEL}
          </Button>
          <Button onClick={() => saveSortPage()} type="primary">
            {i18n.SAVE}
          </Button>
        </Flex>
      </div>
    );
  };

  const MoveQuestion = () => {
    const { questionid, position, pagePosition } = currentQuestion;
    let jsonDataCopy = getStorage("SURVEY_" + surveyid, true);

    //remove a thank you page
    let newPages = jsonDataCopy.pages.filter((page) => page.id !== 2);

    const [afterQuestionId, setAfterQuestionId] = useState(null);
    const [innerPageId, setInnerPageId] = useState(null);

    const saveSortQuestion = () => {
      dispatch(setProgressLoading(true));
      const originalQuestion = newPages[pagePosition].questions[position];

      //insere após uma questão
      if (afterQuestionId && afterQuestionId !== null) {
        let newPagesEdited = [];
        newPages.forEach((pg) => {
          let newQuestionsEdited = [];
          pg.questions.forEach((q) => {
            if (originalQuestion.id !== q.id) {
              newQuestionsEdited.push(q);
              if (afterQuestionId.questId === q.id) {
                newQuestionsEdited.push(originalQuestion);
              }
            }
          });
          pg.questions = newQuestionsEdited;
          newPagesEdited.push(pg);
        });
        newPages.pages = newPagesEdited;
      } else {
        newPages[pagePosition].questions.splice(position, 1);
        newPages[innerPageId.posPage].questions.splice(0, 0, originalQuestion);
      }

      jsonDataCopy.pages = [...newPages, jsonDataCopy.pages.pop()];
      setStorage("SURVEY_" + surveyid, jsonDataCopy, true);
      setShowModalMoveQuestion(false);
      globalSurveySave({ message: i18n.MOVED_QUESTION });
      setCurrentQuestion(null);
    };

    const removeTagsRegex = (htrmlText) => {
      var htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
      htrmlText = htrmlText.replaceAll(htmlRegexG, "");
      return htrmlText;
    };

    return (
      <>
        <div id="sortable-question">
          {newPages.map((page, i) => {
            return (
              <React.Fragment key={`move-page-box` + i}>
                <div className="page">{page.name}</div>
                <div
                  className={`question ${
                    innerPageId !== null && page.id === innerPageId.pageId
                      ? "marked"
                      : ""
                  }`}
                  onClick={() => {
                    setAfterQuestionId(null);
                    setInnerPageId({ pageId: page.id, posPage: i });
                  }}
                >
                  {i18n.TOP_OF_PAGE}
                </div>
                {page.questions.map((quest, j) => {
                  const textLabel = quest.title[jsonDataCopy.language];

                  return (
                    <div
                      className={`question ${
                        afterQuestionId !== null &&
                        quest.id === afterQuestionId.questId
                          ? "marked"
                          : ""
                      } ${questionid === quest.id ? "inactive" : ""}`}
                      key={`move-question-box` + j}
                      onClick={() => {
                        if (questionid !== quest.id) {
                          setInnerPageId(null);
                          setAfterQuestionId({
                            questId: quest.id,
                            posQuestion: j,
                            posPage: i,
                          });
                        }
                      }}
                    >
                      {`Depois de #${quest.id} - ${removeTagsRegex(textLabel)}`}
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
        <Flex
          gap="small"
          wrap="wrap"
          justify="right"
          style={{ marginTop: "10px" }}
        >
          <Button onClick={() => setShowModalMoveQuestion(false)}>
            {i18n.CANCEL}
          </Button>
          <Button
            onClick={() => saveSortQuestion()}
            type="primary"
            disabled={afterQuestionId === null && innerPageId === null}
          >
            {i18n.MOVE}
          </Button>
        </Flex>
      </>
    );
  };

  const openEditionModal = (dataQuestion) => {
    const { questionid, position, pagePosition } = dataQuestion;

    const questionData = jsonData.pages[pagePosition].questions[position];

    setCurrentQuestion({
      questionid,
      position,
      pagePosition,
    });

    const logicData = jsonData.logics.questions[questionid];
    const logicDataFiltred =
      logicData !== undefined && Object.keys(logicData).length
        ? deepReverse(jsonReader(logicData).slice())
        : "";

    setStorage("EDITION_" + surveyid, questionData, true);
    setStorage("LOGIC_" + surveyid, logicDataFiltred, true);
    setSession("ACTIVE_TAB_" + surveyid, 1);
    setShowModalEditor(true);
  };

  const ButtomEditionsPage = ({ dataPage }) => {
    const { pageid, position, pageQtd } = dataPage;

    let compact = ww <= 480;

    const pageData = jsonData.pages[position];

    const contentButtons = (
      <Space direction="horizontal">
        <Space>
          <Tooltip title={i18n.EDIT}>
            <Button
              size="small"
              onClick={() => {
                const logicData = jsonData.logics.pages[pageid];
                const logicDataFiltred =
                  logicData !== undefined && Object.keys(logicData).length
                    ? deepReverse(jsonReader(logicData).slice())
                    : "";

                setCurrentPage({ pageid, position });
                setStorage("EDITION_PAGE_" + surveyid, pageData, true);
                setStorage("LOGIC_PAGE_" + surveyid, logicDataFiltred, true);
                setSession("ACTIVE_TAB_" + surveyid, 1);
                setShowModalEditorPage(true);
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          {pageid !== 2 && (
            <>
              <Tooltip title={i18n.DUPLICATE}>
                <Button
                  size="small"
                  onClick={() => duplicatePage({ pageid, position })}
                >
                  <CopyOutlined />
                </Button>
              </Tooltip>
              {pageQtd > 2 && (
                <>
                  <Tooltip title={i18n.MOVE}>
                    <Button
                      size="small"
                      onClick={() => {
                        setCurrentPage(dataPage);
                        setShowModalMovePage(true);
                      }}
                    >
                      <DragOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title={i18n.DELETE}>
                    <Button
                      size="small"
                      onClick={() => {
                        setShowModalDeletePage(true);
                        setCurrentPage({ pageid, position });
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </Space>
      </Space>
    );
    return (
      <div className="buttom-editions">
        {compact ? (
          <Popover placement="topLeft" content={contentButtons} trigger="click">
            <Button size="small" type="primary">
              <MoreOutlined />
            </Button>
          </Popover>
        ) : (
          contentButtons
        )}
      </div>
    );
  };

  const ButtomEditionsQuestion = ({ dataQuestion }) => {
    const { questionid, position, pagePosition } = dataQuestion;
    let compact = ww <= 480;

    const questionData = jsonData.pages[pagePosition].questions[position];

    const contentButtons = (
      <Space direction="horizontal">
        <Space>
          {questionData.type !== "action" && (
            <Tooltip title={i18n.EDIT}>
              <Button
                size="small"
                type="default"
                onClick={() => {
                  setCurrentQuestion({ questionid, position, pagePosition });

                  const logicData = jsonData.logics.questions[questionid];
                  const logicDataFiltred =
                    logicData !== undefined && Object.keys(logicData).length
                      ? deepReverse(jsonReader(logicData).slice())
                      : "";

                  setStorage("EDITION_" + surveyid, questionData, true);
                  setStorage("LOGIC_" + surveyid, logicDataFiltred, true);
                  setSession("ACTIVE_TAB_" + surveyid, 1);
                  setShowModalEditor(true);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          )}

          <Tooltip title={i18n.DUPLICATE}>
            <Button
              size="small"
              type="default"
              onClick={() =>
                duplicateQuestion({ questionid, position, pagePosition })
              }
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={i18n.MOVE}>
            <Button
              size="small"
              type="default"
              onClick={() => {
                setShowModalMoveQuestion(true);
                setCurrentQuestion({ questionid, position, pagePosition });
              }}
            >
              <DragOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={i18n.DELETE}>
            <Button
              size="small"
              type="default"
              onClick={() => {
                setShowModalDeleteQuestion(true);
                setCurrentQuestion({ questionid, position, pagePosition });
              }}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Space>
      </Space>
    );

    return (
      <div className="buttom-editions">
        {compact ? (
          <Popover placement="topLeft" content={contentButtons} trigger="click">
            <Button size="small" type="default">
              <MoreOutlined />
            </Button>
          </Popover>
        ) : (
          contentButtons
        )}
      </div>
    );
  };

  const QuestionComponents = (qData) => {
    const { data } = qData;

    switch (data.type) {
      case "nps":
        return <NPSComponent data={qData} />;
      case "text":
        return <PlainTextComponent data={qData} />;
      case "language-dropdown":
        return <DropdownLanguageComponent data={qData} />;
      case "radiobox":
        return <RadioboxComponent data={qData} />;
      case "csat":
        return <CSATComponent data={qData} />;
      case "ces":
        return <CESComponent data={qData} />;
      case "checkbox":
        return <CheckboxComponent data={qData} />;
      case "optin":
        return <OptinComponent data={qData} />;
      case "dropdown":
        return <DropdownComponent data={qData} />;
      case "input":
        return <InputComponent data={qData} />;
      case "textarea":
        return <TextareaComponent data={qData} />;
      case "auto-complete":
        return <AutoCompleteComponent data={qData} />;
      case "image-upload":
        return <ImageUploadComponent data={qData} />;
      case "reset-button":
        return <ResetButtonComponent data={qData} />;
      case "mosaic":
        return <MosaicComponent data={qData} />;
      default:
        return <></>;
    }
  };

  const EditPageContent = () => {
    const [activeTab, setActiveTab] = useState(
      getSession("ACTIVE_TAB_" + surveyid)
    );
    const onChange = (key) => {
      setSession("ACTIVE_TAB_" + surveyid, key);
      setActiveTab(key);
    };

    return (
      <div id="build-page-question-layout">
        <Tabs
          defaultActiveKey={activeTab}
          onChange={onChange}
          items={[
            {
              key: "1",
              label: i18n.PAGE.toUpperCase(),
              children: <AbaPage />,
            },
            {
              key: "2",
              label: "LÓGICA",
              children: <AbaLogicPage pageData={currentPage} />,
            },
          ]}
        />
        <Space
          style={{
            width: "100%",
            justifyContent: "right",
            paddingTop: "20px",
          }}
        >
          <Button
            onClick={() => {
              setShowModalEditorPage(false);
              setCurrentPage(null);
              dispatch(setOpenEditorPage(false));
              deleteStorage("EDITION_PAGE_" + surveyid);
              deleteStorage("LOGIC_PAGE_" + surveyid);
              deleteSession("ACTIVE_TAB_" + surveyid);
            }}
          >
            {i18n.CANCEL}
          </Button>
          <Button
            onClick={() => {
              dispatch(setProgressLoading(true));
              globalSurveySave({ redirect: true });
            }}
            type="primary"
          >
            {i18n.SAVE}
          </Button>
        </Space>
      </div>
    );
  };

  const EditQuestionContent = () => {
    const [activeTab, setActiveTab] = useState(
      getSession("ACTIVE_TAB_" + surveyid)
    );
    const onChange = (key) => {
      setSession("ACTIVE_TAB_" + surveyid, key);
      setActiveTab(key);
    };

    return (
      <div id="build-page-question-layout">
        <Tabs
          defaultActiveKey={activeTab}
          onChange={onChange}
          items={[
            {
              key: "1",
              label: i18n.QUESTION.toUpperCase(),
              children: <AbaQuestion />,
            },
            {
              key: "2",
              label: "LAYOUT",
              children: <AbaLayout />,
            },
            {
              key: "3",
              label: i18n.LOGIC.toUpperCase(),
              children: <AbaLogics questionData={currentQuestion} />,
            },
            {
              key: "4",
              label: i18n.TRANSLATION.toUpperCase(),
              children: <AbaTranslate />,
            },
          ]}
        />
        <Space
          style={{
            width: "100%",
            justifyContent: "right",
            paddingTop: "20px",
          }}
        >
          <Button
            onClick={() => {
              setShowModalEditor(false);
              setCurrentQuestion(null);
              dispatch(setOpenEditor(false));
              deleteStorage("EDITION_" + surveyid);
              deleteStorage("LOGIC_" + surveyid);
              deleteSession("ACTIVE_TAB_" + surveyid);
            }}
          >
            {i18n.CANCEL}
          </Button>
          <Button
            onClick={() => {
              dispatch(setProgressLoading(true));
              globalSurveySave({ redirect: true });
            }}
            type="primary"
          >
            {i18n.SAVE}
          </Button>
        </Space>
      </div>
    );
  };

  const EditActionContent = () => {
    const [onSavingAction, setOnSavingAction] = useState(false);

    return (
      <div id="build-page-action-layout">
        <Space
          style={{
            width: "100%",
            justifyContent: "right",
            paddingTop: "20px",
          }}
        >
          <Button
            onClick={() => {
              setShowModalAction(false);
              setCurrentQuestion(null);
              deleteStorage("ACTION_" + surveyid);
            }}
          >
            {i18n.CANCEL}
          </Button>
          <Button
            onClick={() => {
              setOnSavingAction(true);
              messageApi.info(i18n.SAVING + "...", 3, () => {
                setTimeout(() => {
                  globalSurveySave({ background: true, redirect: true });
                  setOnSavingAction(false);
                }, 1000);
              });
            }}
            type="primary"
            loading={onSavingAction}
          >
            {i18n.SAVE}
          </Button>
        </Space>
      </div>
    );
  };

  const getLogic = (id, type) => {
    let textGenerated = readLogics(id, surveyid, type);
    setLogicText(textGenerated[0]);
    setShowModalLogic(!showModalLogic);
  };

  return (
    <>
      {contextHolder}
      {contextHolderModal}
      {progressLoadingX && <Loading type="progress" />}
      <div id="build-page">
        {!loading && jsonData && Object.keys(jsonData).length > 0 && (
          <>
            <HorizontalMenu />
            {jsonData.pages &&
              jsonData.pages.map((page, indexPage) => (
                <React.Fragment key={`page-block-${indexPage}`}>
                  <div
                    className={`page-block ${!page.active ? "inactive" : ""}`}
                  >
                    <div className="edition-page-wrap">
                      <div className="wrapped">
                        <div className="id">#PID {page.id}</div>
                        <div className="title">{page.name}</div>
                        {jsonData.logics.pages[page.id] && (
                          <div
                            className="logic"
                            onClick={() => getLogic(page.id, "pages")}
                            title={i18n.PAGE_HAS_LOGIC}
                          >
                            <BranchesOutlined /> {i18n.LOGIC}
                          </div>
                        )}

                        {!page.active && (
                          <div className="inactive">
                            <BsBan /> Inativa
                          </div>
                        )}
                      </div>

                      <ButtomEditionsPage
                        dataPage={{
                          pageid: page.id,
                          position: indexPage,
                          pageQtd: jsonData.pages.length,
                        }}
                      />
                    </div>

                    {page.questions && page.questions.length > 0 ? (
                      page.questions.map((question, indexQuest) => (
                        <div
                          key={`question-block-${indexQuest}`}
                          className={`question-block ${
                            !question.active ? "inactive" : ""
                          }`}
                          id={`a-question-${question.id}`}
                        >
                          <div className="edition-question-wrap">
                            <div className="wrapped">
                              <div className="id">#QID {question.id}</div>
                              {question.require && (
                                <div className="require">
                                  <BsAsterisk /> Obrigatório
                                </div>
                              )}
                              {jsonData.logics.questions[question.id] && (
                                <div
                                  className="logic"
                                  onClick={() =>
                                    getLogic(question.id, "questions")
                                  }
                                  title={i18n.QUESTION_HAS_LOGIC}
                                >
                                  <BranchesOutlined /> {i18n.LOGIC}
                                </div>
                              )}
                              {!question.active && (
                                <div className="inactive">
                                  <BsBan /> {i18n.INACTIVE_LABEL}
                                </div>
                              )}
                            </div>
                            <ButtomEditionsQuestion
                              dataQuestion={{
                                questionid: question.id,
                                position: indexQuest,
                                pagePosition: indexPage,
                              }}
                            />
                          </div>
                          <div
                            className="edit-component-click"
                            onClick={() =>
                              openEditionModal({
                                questionid: question.id,
                                position: indexQuest,
                                pagePosition: indexPage,
                              })
                            }
                          >
                            <QuestionComponents
                              data={question}
                              language={jsonData.language}
                            />
                          </div>
                          <div>
                            <Badge
                              color="#867ca7"
                              size="small"
                              style={{ fontWeight: "bold" }}
                              count={i18n.QUESTION_TYPES[question.type]}
                            />
                          </div>
                          <div className="addition-block">
                            <div className="caption">
                              <span>{i18n.INSERT.toUpperCase()}:</span>
                            </div>
                            <div className="addition-content">
                              <div className="addition-content-box">
                                <div
                                  className="items"
                                  onClick={() =>
                                    addQuestion({ indexPage, indexQuest })
                                  }
                                >
                                  <CheckOutlined /> &nbsp; {i18n.QUESTION}
                                </div>
                                <div
                                  className="items"
                                  onClick={() =>
                                    setShowModalAction(!showModalAction)
                                  }
                                >
                                  <SettingOutlined /> &nbsp; {i18n.ACTION}
                                </div>
                                {page.id !== 2 &&
                                  page.questions.length > 1 &&
                                  page.questions.length - 1 !== indexQuest && (
                                    <div
                                      className="items"
                                      onClick={() =>
                                        breakPage(indexPage, indexQuest)
                                      }
                                    >
                                      <ColumnHeightOutlined /> &nbsp;
                                      {i18n.BREAK_PAGE}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="empty-page">
                        <Empty
                          description={
                            <>
                              {i18n.MESSAGE_EMPTY_BUILD_PAGE}
                              <br />
                              <br />
                              <Button
                                type="primary"
                                shape="round"
                                icon={<PlusOutlined />}
                                size="small"
                                onClick={() =>
                                  addQuestion({
                                    indexPage,
                                    indexQuest: 0,
                                    isEmptyPage: true,
                                  })
                                }
                              >
                                {i18n.ADD}
                              </Button>
                            </>
                          }
                        />
                      </div>
                    )}
                  </div>
                  {page.id !== 2 && (
                    <Divider>
                      <Flex gap="small">
                        <Button
                          type="text"
                          icon={<FileAddOutlined />}
                          onClick={() => addNewPage(page.id)}
                        >
                          {i18n.NEW_PAGE}
                        </Button>
                        {jsonData.pages.length > indexPage + 2 && (
                          <Button
                            type="text"
                            icon={<VerticalAlignMiddleOutlined />}
                            onClick={() => mergePages(page.id, indexPage)}
                          >
                            {i18n.MERGE_PAGES}
                          </Button>
                        )}
                      </Flex>
                    </Divider>
                  )}
                </React.Fragment>
              ))}
          </>
        )}
        {!loading && jsonData && Object.keys(jsonData).length === 0 && (
          <EmptyBox type="survey-do-not-found" />
        )}

        <Modal
          title="Modo Edição"
          open={showModalEditor || openEditor}
          width={1000}
          footer={null}
          onCancel={() => {
            setShowModalEditor(false);
            setCurrentQuestion(null);
            dispatch(setOpenEditor(false));
            deleteStorage("EDITION_" + surveyid);
            deleteStorage("LOGIC_" + surveyid);
            deleteSession("ACTIVE_TAB_" + surveyid);
          }}
          closable={false}
          styles={{ body: { padding: "0px" } }}
          style={{ padding: "0px" }}
        >
          <EditQuestionContent />
        </Modal>

        <Modal
          title="Editando Ação"
          open={showModalAction}
          width={1000}
          footer={null}
          closable={false}
          styles={{ body: { padding: "0px" } }}
          style={{ padding: "0px" }}
        >
          <EditActionContent />
        </Modal>

        <Modal
          title={i18n.EDIT_PAGE}
          open={showModalEditorPage || openEditorPage}
          width={1000}
          closable={false}
          styles={{ body: { padding: "0px" } }}
          style={{ padding: "0px" }}
          footer={null}
          onCancel={() => setShowModalEditorPage(false)}
          onOk={() => setShowModalEditorPage(false)}
        >
          <EditPageContent />
        </Modal>

        <Modal
          title={i18n.MOVE_PAGE}
          open={showModalMovePage}
          closable={true}
          onCancel={() => setShowModalMovePage(false)}
          footer={null}
          styles={{ body: { padding: "0px" } }}
          style={{ padding: "0px" }}
        >
          <MovePage />
        </Modal>

        <Modal
          title="Mover Questão para:"
          open={showModalMoveQuestion}
          footer={null}
          styles={{ body: { padding: "0px" } }}
          style={{ padding: "0px" }}
          closable={false}
        >
          <MoveQuestion />
        </Modal>

        <Modal
          title="Excluir Página"
          open={showModalDeletePage}
          onOk={() => deletePage()}
          cancelText="Cancelar"
          onCancel={() => {
            setShowModalDeletePage(false);
            setCurrentPage(null);
          }}
        >
          Tem certeza que deseja excluir a página de
          <strong>{currentPage !== null && ` ID ${currentPage.pageid}`}</strong>
          ?
        </Modal>
        <Modal
          title="Excluir Questão"
          open={showModalDeleteQuestion}
          cancelText="Cancelar"
          onOk={() => deleteQuestion()}
          onCancel={() => {
            setShowModalDeleteQuestion(false);
            setCurrentQuestion(null);
          }}
        >
          Tem certeza que deseja excluir a questão de
          <strong>
            {currentQuestion !== null && ` ID ${currentQuestion.questionid}`}
          </strong>
          ?
        </Modal>
        <Modal
          title="Lógica"
          open={showModalLogic}
          footer={null}
          onCancel={() => setShowModalLogic(!showModalLogic)}
        >
          {logicText}
        </Modal>
      </div>
    </>
  );
};

export default BuilderHomePage;
