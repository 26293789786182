import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import moment from "moment";

console.log(`
 ____  _  _  _____  _  _  _  _  _____\r
(_  _)( \\( )(  _  )( \\/ )( \\/ )(  _  )\r
 _)(_  )  (  )(_)(  \\  /  \\  /  )(_)(\r
(____)(_)\\_)(_____)  \\/   (__) (_____)\r
)))))))))))))))))))(((((((((((((((((((\r
(((( D  E  V  E  L  O  P  E  R  S ))))\r
)))))))))))))))))))(((((((((((((((((((\r
((♥ FEITO COM AMOR - © INOVYO ${moment().format("YYYY")} ♥))\r
)))))))))))))))))))(((((((((((((((((((\r
`
);

if (window.location.hostname.indexOf("inovyo") >= 0) {
  console.log = function () {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
